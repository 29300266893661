import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { getImageUrl, imagePlaceholder } from '@/utils/urls';
import { getUnpaidWonCatalogs } from '@/redux/modules/wonItems/wonItems.selectors';
import { useAppSelector } from '@/redux/hooks';
import { useMemo } from 'react';

const WON_ITEMS_BANNER_IMAGE_WIDTH = 64;
const MAX_WON_ITEMS_BANNER_IMAGES = 2;

export const WonItemsBannerImages = () => {
    const unpaidWonCatalogs = useAppSelector(getUnpaidWonCatalogs);

    const firstUnpaidWonCatalog = useMemo(() => {
        return unpaidWonCatalogs[0];
    }, [unpaidWonCatalogs]);

    const imageUrls = useMemo(() => {
        if (!firstUnpaidWonCatalog) {
            return [];
        }
        const allImageUrls = firstUnpaidWonCatalog.items.map((itemSummary) => {
            return getImageUrl({
                catalogId: firstUnpaidWonCatalog.catalogId,
                imageNumber: 1,
                itemId: itemSummary.itemId,
                sellerId: firstUnpaidWonCatalog.sellerId,
                width: WON_ITEMS_BANNER_IMAGE_WIDTH,
            });
        });
        return allImageUrls.slice(0, MAX_WON_ITEMS_BANNER_IMAGES);
    }, [firstUnpaidWonCatalog]);

    return (
        <div className="flex -space-x-md smMax:hidden">
            {imageUrls.map((imageUrl, index) => (
                <div
                    className={cn('rounded-full border border-border-secondary', {
                        'z-10': index === 0,
                    })}
                >
                    <img
                        alt={`${firstUnpaidWonCatalog.items[0].title} - ${index}`}
                        className="h-2xl w-2xl rounded-full"
                        key={index}
                        loading="eager"
                        onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.src = imagePlaceholder;
                        }}
                        src={imageUrl}
                    />
                </div>
            ))}
        </div>
    );
};
