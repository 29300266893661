import { BodyPrimary, H2 } from '@liveauctioneers/hammer-ui-core/text';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { CaretRight } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { getIsWhiteLabel } from '@/redux/modules/whiteLabel';
import { getWonItemsPageUrl } from '@/utils/urls';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { useNavigate } from 'react-router-dom';
import { useTrackViewWonItemsBannerClickedAnalytics } from '@/utils/analytics/navigation/trackViewWonItemsBannerClicked.analytics';
import { WonItemsBannerImages } from './components/WonItemsBannerImages/WonItemsBannerImages';

type Props = {
    className?: string;
};

export const ViewWonItemsBanner = ({ className }: Props) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const trackViewWonItemsBannerClicked = useTrackViewWonItemsBannerClickedAnalytics();

    const onViewWonItemsClicked = useCallback(() => {
        trackViewWonItemsBannerClicked();
        navigate(getWonItemsPageUrl());
    }, [navigate, trackViewWonItemsBannerClicked]);

    return (
        <div
            className={cn(
                'm-auto my-md grid w-full max-w-[1200px] grid-cols-[max-content_1fr_min-content] items-center gap-sm rounded-md bg-background-success-dark p-sm shadow-01 smMin:pr-md',
                //  Mobile
                'smMax:my-sm smMax:grid-cols-1 smMax:grid-rows-[min-content_min-content_min-content] smMax:gap-0',
                { 'mb-0': isWhiteLabel },
                className
            )}
            data-testid="won-items-banner"
        >
            <WonItemsBannerImages />
            <div className="flex flex-col gap-2xs">
                <H2 color="inverse">
                    {formatMessage({ id: 'viewWonItemsBanner.congratulations' })}&nbsp;
                    <span
                        aria-label="celebration"
                        role="img"
                    >
                        🎉
                    </span>
                </H2>
                <BodyPrimary color="inverse">{formatMessage({ id: 'viewWonItemsBanner.completeAccount' })}</BodyPrimary>
            </div>
            <Button
                className="smMax:mt-sm smMax:w-full"
                iconRight={CaretRight}
                onClick={onViewWonItemsClicked}
                style="inverse"
            >
                {formatMessage({ id: 'viewWonItemsBanner.viewWonItem' })}
            </Button>
        </div>
    );
};
