import { createSelector } from '@reduxjs/toolkit';
import { filterCatalogs, filterCatalogsByReg, filterItems, filterSellers } from './sorting';
import { getAllCurrentUserCatalogRegistrationIds } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import { getCatalogIdsForSeller, getCatalogsByIds } from './catalog/catalogs/catalog.selectors';
import { getFollowedSellerIds } from './followSeller';
import { getHousesById } from './seller';
import {
    getItemIdsForCatalog,
    getItemIdsForSeller,
    getItemsForCatalog,
    getItemSummaries,
} from './item/summary/itemSummary.selectors';
import { getSavedSearchItemIds } from './savedSearch';
import { getSellersCatalogCounts } from './sellerCatalogCounts';
import { getStorefrontItemIds } from './storefront';
import { GlobalState } from '@/redux/store';

export const getNextCatalogItemIds = (state: any, catalogId: number, itemId: number, count: number) => {
    const items = getItemsForCatalog(state, catalogId);
    const [theItem] = items.filter((item) => item.itemId === itemId);

    let returnItems = items.filter((item) => {
        return item.index > theItem?.index;
    });

    if (returnItems.length < count) {
        return [...returnItems, ...items.slice(0, count - returnItems.length)].map(({ itemId }) => itemId);
    } else {
        return returnItems.slice(0, count).map(({ itemId }) => itemId);
    }
};

export const getCatalogContainsBuyNowLots = (state: any, catalogId: number) => {
    const ids = getItemIdsForCatalog(state, catalogId);
    const all = getItemSummaries(state, ids);
    return all.filter((x) => x.buyNowStatus === 1).length > 0;
};

type GetFollowedSellersParams = {
    count?: number | 'all';
    page?: number;
    sort?: string;
    terms?: string;
};

const followedSellersParamsSelector = (_: any, params: GetFollowedSellersParams = {}) => params;

export const getSortedFollowedSellersIds = createSelector(
    [(state: any) => state, followedSellersParamsSelector],
    (state, { count, page, sort, terms }) => {
        const ids = getFollowedSellerIds(state);
        const sellers = getHousesById(state, ids);
        const catalogCounts = getSellersCatalogCounts(state, ids);
        const all = sellers.map((seller) => ({
            ...seller,
            catalogCounts: catalogCounts.find(({ sellerId }) => seller.sellerId === sellerId),
        }));
        const { items = [] } = filterSellers(all, { count, page, sort, terms });
        return items.map((item) => item?.sellerId);
    }
);

type GetMySavedSearchItemsParams = {
    count?: number | 'all';
    page?: number;
    sort?: string;
    terms?: string;
};

export const getMySavedSearchItemIds = (state: any, { count, page, sort, terms }: GetMySavedSearchItemsParams = {}) => {
    const ids = getSavedSearchItemIds(state);
    const all = getItemSummaries(state, ids);
    const results = filterItems(all, { count, page, sort, terms });
    return results.itemIds.length && results.itemIds.length === ids.length ? results.itemIds : ids;
};

type GetSellerCatalogsParams = {
    count?: number | 'all';
    page?: number;
    sort?: string;
    tab?: string;
    terms?: string;
};
export const getSellerCatalogs = (
    state: any,
    sellerId: number,
    { count, page, sort, tab, terms }: GetSellerCatalogsParams = {}
) => {
    const ids = getCatalogIdsForSeller(state, sellerId);
    const all = getCatalogsByIds(state, ids);
    return filterCatalogs(all, { count, page, sort, tab, terms });
};

type GetStorefrontItemsParams = {
    buyNow?: boolean;
    count?: number | 'all';
    page?: number;
    sort?: string;
    terms?: string;
};
export const getStorefrontItems = (state: any, { buyNow, count, page, sort, terms }: GetStorefrontItemsParams = {}) => {
    const ids = getStorefrontItemIds(state);
    const all = getItemSummaries(state, ids);
    const filterBuyNow = buyNow ? all.filter((x) => x.buyNowStatus === 1) : all;
    return filterItems(filterBuyNow, { count, page, sort, terms });
};

type GetMyAuctionsParams = {
    count?: number | 'all';
    page?: number;
    sort?: string;
    tab?: string;
    terms?: string;
};
const myAuctionsParamsSelector = (_: GlobalState, params: GetMyAuctionsParams = {}) => params;

export const getMyAuctions = createSelector(
    [getAllCurrentUserCatalogRegistrationIds, (state: GlobalState) => state, myAuctionsParamsSelector],
    (catalogIds, state, { count, page, sort, tab, terms }) => {
        const all = getCatalogsByIds(state, catalogIds);
        return filterCatalogsByReg(state, all, { count, page, sort, tab, terms });
    }
);

export const getMostPopularItemsByBids = (state: any, sellerId: number) => {
    const ids = getItemIdsForSeller(state, sellerId);
    const all = getItemSummaries(state, ids);
    const { itemIds } = filterItems(all, {
        count: 20,
        page: 1,
        sort: 'MOST_BIDS',
        tab: 'upcoming',
    });
    return itemIds || [];
};

export const getCatalogsForFollowedSellers = createSelector([(state) => state], (state): number[] => {
    const followedSellerIds = getFollowedSellerIds(state);
    let followedSellerCatalogs = [];
    let followedSellerCatalogIds = [];

    // limiting the number of items per seller to keep data small
    followedSellerIds.forEach((id) => {
        const liveCatalogs = getSellerCatalogs(state, id, {
            count: 20,
            page: 1,
            sort: 'DATE_ASCENDING',
            tab: 'live',
        });

        if (liveCatalogs && liveCatalogs.items && liveCatalogs.items.length > 0) {
            followedSellerCatalogs = followedSellerCatalogs.concat(liveCatalogs.items);
            liveCatalogs.items.forEach((item) => {
                if (item && item.catalogId) {
                    followedSellerCatalogIds = followedSellerCatalogIds.concat(item.catalogId);
                }
            });
        }
    });

    return followedSellerCatalogIds.slice(0, 16);
});
