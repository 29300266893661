import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

export const CarouselLoadingWrapper = ({ children, className, id, ...props }: Props) => {
    return (
        <div
            className={cn('relative mx-auto', className)}
            id={id}
            {...props}
        >
            <div className="transition-opacity duration-300">{children}</div>
        </div>
    );
};

export default CarouselLoadingWrapper;
