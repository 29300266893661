import { fetchBidderDataIfNeeded } from '@/redux/modules/account/user/user.actions';
import { fetchLAPaymentCardIfNeeded, laPaymentDefaultCardSelector } from '@/redux/modules/creditCard';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getBidderMobileVerified,
    getDefaultAddress,
    getDefaultAddressIsVerified,
    isUserAtLeastPartiallyRegistered,
} from '@/redux/modules/account/user/user.selectors';
import { getIsWhiteLabel } from '@/redux/modules/whiteLabel';
import { getUnpaidWonCatalogs } from '@/redux/modules/wonItems/wonItems.selectors';
import { InlineButton } from '@liveauctioneers/hammer-ui-core/inlineButton';
import { InPageMessage } from '@liveauctioneers/caterwaul-components/lib/InPageMessage/InPageMessage';
import { openCompleteAccountSignUpModal } from '@/redux/modules/modal';
import { PaymentProviders } from '@/enums/paymentProviders';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useApprovalsBids100OrLessExperiment } from '@/hooks/kameleoon/ApprovalsBids100OrLess/useApprovalsBids100OrLessExperiment';
import { useTrackCompleteAccountSetupBannerClicked } from '@/utils/analytics';
import { useTrackCompleteAccountSetupClicked } from '@/hooks/kameleoon/tracking/useTrackCompleteAccountSetup';
import { ViewWonItemsBanner } from './components/ViewWonItemsBanner/ViewWonItemsBanner';
import React from 'react';
import styled, { css } from 'styled-components';

interface CompleteAccountBannerProps {
    className?: string;
}

export const CompleteAccountBanner = ({ className }: CompleteAccountBannerProps) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const userPartiallyRegistered = useAppSelector(isUserAtLeastPartiallyRegistered);
    const defaultAddress = useAppSelector(getDefaultAddress);
    const addressVerified = useAppSelector(getDefaultAddressIsVerified);
    const defaultCreditCard = useAppSelector((state) => laPaymentDefaultCardSelector(state, PaymentProviders.Payrix));
    const mobileVerified = useAppSelector(getBidderMobileVerified);
    const trackCompleteAccountSetupBannerClicked = useTrackCompleteAccountSetupBannerClicked();
    const trackCompleteAccountSetupClicked = useTrackCompleteAccountSetupClicked();
    const hasApprovalsBids100OrLessExperiment = useApprovalsBids100OrLessExperiment();
    const unpaidWonCatalogs = useAppSelector(getUnpaidWonCatalogs);

    const showCompleteAccount =
        userPartiallyRegistered &&
        !(
            Boolean(defaultAddress?.firstName) &&
            Boolean(defaultAddress?.lastName) &&
            Boolean(defaultAddress?.address1) &&
            addressVerified &&
            Boolean(defaultCreditCard?.id) &&
            mobileVerified
        );

    const showViewWonItemsBanner =
        hasApprovalsBids100OrLessExperiment && showCompleteAccount && unpaidWonCatalogs.length > 0;

    const handleSetupLink = () => {
        dispatch(openCompleteAccountSignUpModal());
        trackCompleteAccountSetupBannerClicked();
        trackCompleteAccountSetupClicked();
    };

    React.useEffect(() => {
        dispatch(fetchBidderDataIfNeeded({ force: true }));
        dispatch(fetchLAPaymentCardIfNeeded());
    }, [dispatch]);

    if (showViewWonItemsBanner) {
        return <ViewWonItemsBanner className={className} />;
    }

    return (
        showCompleteAccount && (
            <StyledInPageMessage
                $showBottomMargin={isWhiteLabel}
                className={className}
            >
                <InlineButton
                    onClick={handleSetupLink}
                    size="sm"
                >
                    {formatMessage({ id: 'completeAccount.setupLink' })}
                </InlineButton>
                <FormattedMessage id="completeAccount.toSaveTimeBidding" />
            </StyledInPageMessage>
        )
    );
};

export default CompleteAccountBanner;

const StyledInPageMessage = styled(InPageMessage)<{
    $showBottomMargin: boolean;
}>`
    width: 100%;
    margin: 32px auto 0;
    ${({ $showBottomMargin }) => {
        if ($showBottomMargin) {
            return css`
                margin: 32px auto;
            `;
        } else {
            return css`
                margin: 32px auto 0;
            `;
        }
    }}

    max-width: ${({ theme }) => theme.breakpoints.pageWidthNum}px;

    @media (max-width: ${({ theme }) => theme.breakpoints.pageWidth}) {
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
`;
