import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const trackViewWonItemsBannerClickedAnalytics = (payload: TrackingPayload) => {
    track(TrackingEvents.GlobalNavigation.ViewWonItemsBannerClicked, payload);
};

export const useTrackViewWonItemsBannerClickedAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(() => {
        trackViewWonItemsBannerClickedAnalytics({
            pageName: getAmplitudePageNameFromPath(pathname),
            url,
        });
    }, [pathname, url]);
};
