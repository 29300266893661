import { KameleoonExperiments, KameleoonVariants } from '@/hooks/kameleoon/kameleoonExperiments';
import { useExperimentBase } from '@/hooks/kameleoon/utils/useExperimentBase';
import { useTrackExposedToExperiment } from '@/utils/analytics/experiments/trackExposedToExperiment.analytics';

const useIsInApprovalsBids100OrLessExperiment = () =>
    useExperimentBase(
        KameleoonExperiments.ApprovalsBids100OrLess,
        KameleoonVariants.ApprovalsBids100OrLess.AutoApprovalsVariant
    );

const useApprovalsBids100OrLessControl = () =>
    useExperimentBase(KameleoonExperiments.ApprovalsBids100OrLess, KameleoonVariants.ApprovalsBids100OrLess.Control);

export const useApprovalsBids100OrLessExperiment = () => {
    const hasApprovalsBids100OrLessVariant = useIsInApprovalsBids100OrLessExperiment();
    const isInControl = useApprovalsBids100OrLessControl();

    let variant = 'Off';
    if (hasApprovalsBids100OrLessVariant) {
        variant = 'On';
    } else if (isInControl) {
        variant = 'Control';
    }
    const trackExposure = useTrackExposedToExperiment('Approvals Bids 100 Or Less', variant);
    trackExposure();

    return hasApprovalsBids100OrLessVariant;
};
