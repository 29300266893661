import { Carousel } from '@liveauctioneers/hammer-ui-core/carousel';
import { ComponentProps } from 'react';
import CarouselLoadingWrapper from '../CarouselLoadingWrapper/CarouselLoadingWrapper';

type Props = {
    className?: string;
} & Partial<ComponentProps<typeof Carousel>>;

const SideScroller = ({ cards = [], className, ...props }: Props) => {
    return (
        <CarouselLoadingWrapper className={className}>
            <Carousel
                cards={cards}
                {...props}
            />
        </CarouselLoadingWrapper>
    );
};

export default SideScroller;
